<template>
<v-container>
  <v-row>
    <v-col>
      <v-btn to="/organization/settings/users" color="secondary">
        <v-icon small left>
          fa-arrow-left
        </v-icon>
        Back to list
      </v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <h2>
                User Profile
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field filled label="Name" disabled :value="user.name" />
            </v-col>
            <v-col>
              <v-text-field filled label="Email" disabled :value="user.email" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <h2>
            User Role
          </h2>
          <v-select :disabled="user.id === currentId" v-model="user.role" :items="availableRoles" />
          <v-btn @click="updateUserRole" :loading="loading" :disabled="user.id === currentId">
            Update
          </v-btn>
          <span v-if="user.id === currentId">
            You cannot change your own role.
          </span>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import event, { Events } from '../../../event'
import { Roles, RoleOptions } from '@/js/Roles'

export default {
  data: () => ({
    RoleOptions,
    loading: false,
    user: {},
    availableRoles: []
  }),
  computed: {
    ...mapState({
      currentId: state => state.User.id
    }),
    ...mapGetters({
      url: 'Organization/apiUrl'
    })
  },
  created () {
    this.getUser()
    this.availableRoles = RoleOptions.filter(role => role.value !== Roles.INDIVIDUAL)
  },
  methods: {
    getUser () {
      this.$axios.get(`${this.url}/user/${this.$route.params.id}`)
        .then(r => {
          this.user = r.data.payload
        })
    },
    updateUserRole () {
      this.loading = true
      this.$axios.put(`${this.url}/user/${this.$route.params.id}`, {
        role: this.user.role
      })
        .then(() => {
          event.emit(Events.SUCCESS, 'User role updated!')
          this.getUser()
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
